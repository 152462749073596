.codeEditor {
    counter-reset: line;
    /*border: 1px solid #ced4da;*/
    color: #E4EBF0;
}

.codeEditor #codeArea {
    outline: none;
    padding-left: 40px !important;
}

.codeEditor pre {
    padding-left: 40px !important;
}

.codeEditor .editorLineNumber {
    position: absolute;
    left: 0px;
    color: #9DA7AE;
    text-align: right;
    width: 20px;
    font-weight: 100;
}

/* Syntax highlighting */
.token.comment,
.token.prolog,
.token.doctype,
.token.cdata {
    color: #81898F;
}
.token.punctuation {
    color: #E4EBF0;
}
.namespace {
    opacity: 0.7;
}
.token.property,
.token.tag,
.token.boolean,
.token.number,
.token.constant,
.token.symbol,
.token.deleted {
    color: #FF6C7A;
}
.token.selector,
.token.attr-name,
.token.string,
.token.char,
.token.builtin,
.token.inserted {
    color: #7CC110;
}
.token.operator,
.token.entity,
.token.url,
.language-css .token.string,
.style .token.string {
    color: #E4EBF0;
}
.token.atrule,
.token.attr-value,
.token.keyword {
    color: #8971FF;
}
.token.function {
    color: #72C3F2;
}
.token.regex,
.token.important,
.token.variable {
    color: #ff9800;
}
.token.important,
.token.bold {
    font-weight: bold;
}
.token.italic {
    font-style: italic;
}
.token.entity {
    cursor: help;
}