@font-face {
  font-display: swap;
  font-family: "Pretendard";
  font-weight: 400;
  font-style: normal;
  src: local(Pretendard Regular),
    url("https://static.spartacodingclub.kr/static/fonts/Pretendard/Pretendard-Regular.subset.woff2")
      format("woff2"),
    url("https://static.spartacodingclub.kr/static/fonts/Pretendard/Pretendard-Regular.subset.woff")
      format("woff"),
    url("https://static.spartacodingclub.kr/static/fonts/Pretendard/Pretendard-Regular.otf");
}

@font-face {
  font-display: swap;
  font-family: "Pretendard";
  font-weight: 500;
  font-style: normal;
  src: local(Pretendard Medium),
    url("https://static.spartacodingclub.kr/static/fonts/Pretendard/Pretendard-Medium.subset.woff2")
      format("woff2"),
    url("https://static.spartacodingclub.kr/static/fonts/Pretendard/Pretendard-Medium.subset.woff")
      format("woff"),
    url("https://static.spartacodingclub.kr/static/fonts/Pretendard/Pretendard-Medium.otf");
}

@font-face {
  font-display: swap;
  font-family: "Pretendard";
  font-weight: 600;
  font-style: normal;
  src: local(Pretendard SemiBold),
    url("https://static.spartacodingclub.kr/static/fonts/Pretendard/Pretendard-SemiBold.subset.woff2")
      format("woff2"),
    url("https://static.spartacodingclub.kr/static/fonts/Pretendard/Pretendard-SemiBold.subset.woff")
      format("woff"),
    url("https://static.spartacodingclub.kr/static/fonts/Pretendard/Pretendard-SemiBold.otf");
}

@font-face {
  font-display: swap;
  font-family: "Pretendard";
  font-weight: 700;
  font-style: normal;
  src: local(Pretendard Bold),
    url("https://static.spartacodingclub.kr/static/fonts/Pretendard/Pretendard-Bold.subset.woff2")
      format("woff2"),
    url("https://static.spartacodingclub.kr/static/fonts/Pretendard/Pretendard-Bold.subset.woff")
      format("woff"),
    url("https://static.spartacodingclub.kr/static/fonts/Pretendard/Pretendard-Bold.otf");
}

.commentEditor .quill {
  min-height: 57px;
}

.commentEditor .ql-container {
  min-height: 56px;
}

.commentEditor .ql-editor {
  min-height: 56px;
  padding: 14px 20px !important;
}

.quill {
  min-height: 280px;
}

@media (min-width: 1024px) {
  .quill {
    min-height: 280px;
  }
}

@media (min-width: 1024px) {
  .ql-editor {
    color: #6b6e72;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.3;
    letter-spacing: normal;
    font-weight: 500;
    width: 100%;
    min-height: 280px;
    margin: auto;
    padding: 24px 20px !important;
  }
}

.ql-editor {
  color: #6b6e72;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.3;
  letter-spacing: normal;
  font-weight: 500;
  min-height: 200px;
  width: 100%;
  margin: auto;
  padding: 24px 20px !important;
}

.quill > .ql-container > .ql-editor.ql-blank::before {
  color: #c7c9cb;
  font-style: normal;
  font-size: 14px;
  white-space: pre-wrap;
  line-height: 1.5;
  padding: 5px 5px;
}

.ql-editor img {
  display: block;
}

.ql-editor iframe {
  width: 790px;
  height: 445px;
  margin: 44px auto;
}

.ql-editor {
  box-sizing: border-box;
  line-height: 1.42;
  outline: none;
  padding: 12px 0;
  tab-size: 4;
  -moz-tab-size: 4;
  text-align: left;
  white-space: pre-wrap;
  word-wrap: break-word;
}

.ql-video {
  display: block;
  max-width: 100%;
}

.ql-video.ql-align-center {
  margin: 0 auto;
}

.ql-video.ql-align-right {
  margin: 0 0 0 auto;
}

.ql-bg-black {
  background-color: #000;
}

.ql-bg-red {
  background-color: #e60000;
}

.ql-bg-orange {
  background-color: #f90;
}

.ql-bg-yellow {
  background-color: #ff0;
}

.ql-bg-green {
  background-color: #008a00;
}

.ql-bg-blue {
  background-color: #06c;
}

.ql-bg-purple {
  background-color: #93f;
}

.ql-color-white {
  color: #fff;
}

.ql-color-red {
  color: #e60000;
}

.ql-color-orange {
  color: #f90;
}

.ql-color-yellow {
  color: #ff0;
}

.ql-color-green {
  color: #008a00;
}

.ql-color-blue {
  color: #06c;
}

.ql-color-purple {
  color: #93f;
}

.ql-font-serif {
  font-family: Georgia, Times New Roman, serif;
}

.ql-font-monospace {
  font-family: Monaco, Courier New, monospace;
}

.ql-size-small {
  font-size: 12px !important;
}

.ql-size-large {
  font-size: 1.5em;
}

.ql-size-huge {
  font-size: 2.5em;
}

.ql-direction-rtl {
  direction: rtl;
  text-align: inherit;
}

.ql-align-center {
  text-align: center;
}

.ql-align-justify {
  text-align: justify;
}

.ql-align-right {
  text-align: right;
}

.ql-editor.ql-blank::before {
  color: rgba(0, 0, 0, 0.6);
  content: attr(data-placeholder);
  font-style: italic;
  left: 20px;
  pointer-events: none;
  position: absolute;
  right: 15px;
  font-weight: 350;
}

.ql-editor * {
  box-sizing: border-box;
}

.ql-hidden {
  display: none;
}

.ql-out-bottom,
.ql-out-top {
  visibility: hidden;
}

.ql-tooltip {
  position: absolute;
  transform: translateY(10px);
}

.ql-tooltip a {
  cursor: pointer;
  text-decoration: none;
}

.ql-tooltip.ql-flip {
  transform: translateY(-10px);
}

.ql-formats {
  display: inline-block;
  vertical-align: middle;
}

.ql-formats:after {
  clear: both;
  content: "";
  display: table;
}

.ql-stroke {
  fill: none;
  stroke: #444;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-width: 2;
}

.ql-stroke-miter {
  fill: none;
  stroke: #444;
  stroke-miterlimit: 10;
  stroke-width: 2;
}

.ql-fill,
.ql-stroke.ql-fill {
  fill: #444;
}

.ql-empty {
  fill: none;
}

.ql-even {
  fill-rule: evenodd;
}

.ql-thin,
.ql-stroke.ql-thin {
  stroke-width: 1;
}

.ql-transparent {
  opacity: 0.4;
}

.ql-direction svg:last-child {
  display: none;
}

.ql-direction.ql-active svg:last-child {
  display: inline;
}

.ql-direction.ql-active svg:first-child {
  display: none;
}

.ql-editor h1 {
  font-size: 2em;
  color: #505254;
}

.ql-editor h2 {
  font-size: 1.75em;
  color: #505254;
}

.ql-editor h3 {
  font-size: 1.17em;
  color: #505254;
}

.ql-editor h4 {
  font-size: 1em;
  color: #505254;
}

.ql-editor h5 {
  font-size: 0.83em;
  color: #505254;
}

.ql-editor h6 {
  font-size: 0.67em;
  color: #505254;
}

.ql-editor a {
  text-decoration: underline;
  text-underline-position: under;
}

.ql-editor u {
  text-decoration: underline;
  text-underline-position: under;
}

.ql-editor blockquote {
  border-left: 4px solid #ccc;
  margin-bottom: 5px;
  margin-top: 5px;
  padding-left: 16px;
  font-weight: 300;
  color: #838689;
  font-size: 18px;
}

.ql-editor code,
.ql-editor pre {
  background-color: #f0f0f0;
  border-radius: 3px;
}

.ql-editor pre {
  white-space: pre-wrap;
  margin-bottom: 5px;
  margin-top: 5px;
  padding: 5px 10px;
}

.ql-editor code {
  font-size: 85%;
  padding: 2px 4px;
}

.ql-editor pre.ql-syntax {
  background-color: #f4f5f6 !important;
  color: #505254 !important;
  padding: 14px 20px;
  margin-top: 20px;
  margin-bottom: 20px;
  overflow: visible;
  border: 1px solid #eaebed;
  border-radius: 8px;
}

.ql-editor img {
  max-width: 100%;
  margin-top: 20px;
  margin-bottom: 20px;
}

.ql-picker {
  color: #444;
  display: inline-block;
  float: left;
  font-size: 14px;
  font-weight: 500;
  height: 24px;
  position: relative;
  vertical-align: middle;
}

.ql-picker-label {
  cursor: pointer;
  display: inline-block;
  height: 100%;
  padding-left: 8px;
  padding-right: 2px;
  position: relative;
  width: 100%;
}

.ql-picker-label::before {
  display: inline-block;
  line-height: 22px;
}

.ql-picker-options {
  background-color: #fff;
  display: none;
  min-width: 100%;
  padding: 4px 8px;
  position: absolute;
  white-space: nowrap;
}

.ql-picker-options .ql-picker-item {
  cursor: pointer;
  display: block;
  padding-bottom: 5px;
  padding-top: 5px;
}

.ql-picker.ql-expanded .ql-picker-label {
  color: #ccc;
  z-index: 2;
}

.ql-picker.ql-expanded .ql-picker-label .ql-fill {
  fill: #ccc;
}

.ql-picker.ql-expanded .ql-picker-label .ql-stroke {
  stroke: #ccc;
}

.ql-picker.ql-expanded .ql-picker-options {
  display: block;
  margin-top: -1px;
  top: 100%;
  z-index: 1;
}

.ql-color-picker,
.ql-icon-picker {
  width: 28px;
}

.ql-color-picker .ql-picker-label,
.ql-icon-picker .ql-picker-label {
  padding: 2px 4px;
}

.ql-color-picker .ql-picker-label svg,
.ql-icon-picker .ql-picker-label svg {
  right: 4px;
}

.ql-icon-picker .ql-picker-options {
  padding: 4px 0px;
}

.ql-icon-picker .ql-picker-item {
  height: 24px;
  width: 24px;
  padding: 2px 4px;
}

.ql-color-picker .ql-picker-options {
  padding: 3px 5px;
  width: 152px;
}

.ql-color-picker .ql-picker-item {
  border: 1px solid transparent;
  float: left;
  height: 16px;
  margin: 2px;
  padding: 0px;
  width: 16px;
}

.ql-picker:not(.ql-color-picker):not(.ql-icon-picker) svg {
  position: absolute;
  margin-top: -9px;
  right: 0;
  top: 50%;
  width: 18px;
}

.ql-picker.ql-header .ql-picker-label[data-label]:not([data-label=""])::before,
.ql-picker.ql-font .ql-picker-label[data-label]:not([data-label=""])::before,
.ql-picker.ql-size .ql-picker-label[data-label]:not([data-label=""])::before,
.ql-picker.ql-header .ql-picker-item[data-label]:not([data-label=""])::before,
.ql-picker.ql-font .ql-picker-item[data-label]:not([data-label=""])::before,
.ql-picker.ql-size .ql-picker-item[data-label]:not([data-label=""])::before {
  content: attr(data-label);
}

.ql-picker.ql-header {
  width: 98px;
}

.ql-picker.ql-header .ql-picker-label::before,
.ql-picker.ql-header .ql-picker-item::before {
  content: "Normal";
}

.ql-picker.ql-header .ql-picker-label[data-value="1"]::before,
.ql-picker.ql-header .ql-picker-item[data-value="1"]::before {
  content: "Heading 1";
}

.ql-picker.ql-header .ql-picker-label[data-value="2"]::before,
.ql-picker.ql-header .ql-picker-item[data-value="2"]::before {
  content: "Heading 2";
}

.ql-picker.ql-header .ql-picker-label[data-value="3"]::before,
.ql-picker.ql-header .ql-picker-item[data-value="3"]::before {
  content: "Heading 3";
}

.ql-picker.ql-header .ql-picker-label[data-value="4"]::before,
.ql-picker.ql-header .ql-picker-item[data-value="4"]::before {
  content: "Heading 4";
}

.ql-picker.ql-header .ql-picker-label[data-value="5"]::before,
.ql-picker.ql-header .ql-picker-item[data-value="5"]::before {
  content: "Heading 5";
}

.ql-picker.ql-header .ql-picker-label[data-value="6"]::before,
.ql-picker.ql-header .ql-picker-item[data-value="6"]::before {
  content: "Heading 6";
}

.ql-picker.ql-header .ql-picker-item[data-value="1"]::before {
  font-size: 2em;
}

.ql-picker.ql-header .ql-picker-item[data-value="2"]::before {
  font-size: 1.5em;
}

.ql-picker.ql-header .ql-picker-item[data-value="3"]::before {
  font-size: 1.17em;
}

.ql-picker.ql-header .ql-picker-item[data-value="4"]::before {
  font-size: 1em;
}

.ql-picker.ql-header .ql-picker-item[data-value="5"]::before {
  font-size: 0.83em;
}

.ql-picker.ql-header .ql-picker-item[data-value="6"]::before {
  font-size: 0.67em;
}

.ql-picker.ql-font {
  width: 108px;
}

.ql-picker.ql-font .ql-picker-label::before,
.ql-picker.ql-font .ql-picker-item::before {
  content: "Sans Serif";
}

.ql-picker.ql-font .ql-picker-label[data-value="serif"]::before,
.ql-picker.ql-font .ql-picker-item[data-value="serif"]::before {
  content: "Serif";
}

.ql-picker.ql-font .ql-picker-label[data-value="monospace"]::before,
.ql-picker.ql-font .ql-picker-item[data-value="monospace"]::before {
  content: "Monospace";
}

.ql-picker.ql-font .ql-picker-item[data-value="serif"]::before {
  font-family: Georgia, Times New Roman, serif;
}

.ql-picker.ql-font .ql-picker-item[data-value="monospace"]::before {
  font-family: Monaco, Courier New, monospace;
}

.ql-picker.ql-size {
  width: 98px;
}

.ql-picker.ql-size .ql-picker-label::before,
.ql-picker.ql-size .ql-picker-item::before {
  content: "Normal";
}

.ql-picker.ql-size .ql-picker-label[data-value="small"]::before,
.ql-picker.ql-size .ql-picker-item[data-value="small"]::before {
  content: "Small";
}

.ql-picker.ql-size .ql-picker-label[data-value="large"]::before,
.ql-picker.ql-size .ql-picker-item[data-value="large"]::before {
  content: "Large";
}

.ql-picker.ql-size .ql-picker-label[data-value="huge"]::before,
.ql-picker.ql-size .ql-picker-item[data-value="huge"]::before {
  content: "Huge";
}

.ql-picker.ql-size .ql-picker-item[data-value="small"]::before {
  font-size: 10px;
}

.ql-picker.ql-size .ql-picker-item[data-value="large"]::before {
  font-size: 18px;
}

.ql-picker.ql-size .ql-picker-item[data-value="huge"]::before {
  font-size: 32px !important;
}

.ql-color-picker.ql-background .ql-picker-item {
  background-color: #fff;
}

.ql-color-picker.ql-color .ql-picker-item {
  background-color: #000;
}

.ql-snow.ql-update-toolbar button {
  height: 30px;
  width: 30px;
  padding: 0 5px;
  background: none;
  cursor: pointer;
  border: none;
  display: inline-block;
}

.ql-snow.ql-toolbar button {
  height: 30px;
  width: 30px;
  padding: 0 5px;
}

@media (hover: hover) {
  .ql-snow.ql-toolbar button:hover {
    background-color: #eaebed;
    border-radius: 8px;
  }
}

/*.ql-snow.ql-toolbar button:hover {*/
/*  */
/*}*/

.ql-snow.ql-update-toolbar button:hover {
  background-color: #eaebed;
  border-radius: 8px;
}

.ql-toolbar {
  position: relative;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  border: none !important;
  background-color: #f4f5f6;
  box-sizing: border-box;
  font-family: "Helvetica Neue", "Helvetica", "Arial", sans-serif;
  padding: 12px 15px !important;
  height: 52px;
}

@media (min-width: 1024px) {
  .ql-toolbar {
    position: relative;
    border: none !important;
    display: block;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
    background-color: #f4f5f6;
    box-sizing: border-box;
    font-family: "Helvetica Neue", "Helvetica", "Arial", sans-serif;
    padding: 13px 15px !important;
    height: 56px;
  }
}

.ql-update-toolbar {
  position: relative;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  border: none !important;
  background-color: #f4f5f6;
  box-sizing: border-box;
  font-family: "Helvetica Neue", "Helvetica", "Arial", sans-serif;
  padding: 12px 15px !important;
  height: 52px;
}

@media (min-width: 1024px) {
  .ql-update-toolbar {
    position: relative;
    border: none !important;
    display: block;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
    background-color: #f4f5f6;
    box-sizing: border-box;
    font-family: "Helvetica Neue", "Helvetica", "Arial", sans-serif;
    padding: 13px 15px !important;
    height: 56px;
  }
}

.ql-toolbar .ql-formats {
  margin-right: 10px !important;
}

.ql-toolbar .ql-picker-label {
  border: 1px solid transparent;
}

.ql-toolbar .ql-picker-options {
  border: 1px solid transparent;
  box-shadow: rgba(0, 0, 0, 0.2) 0 2px 8px;
}

.ql-toolbar .ql-picker.ql-expanded .ql-picker-label {
  border-color: #ccc;
}

.ql-toolbar .ql-picker.ql-expanded .ql-picker-options {
  border-color: #ccc;
}

.ql-toolbar .ql-color-picker .ql-picker-item.ql-selected,
.ql-toolbar .ql-color-picker .ql-picker-item:hover {
  border-color: #000;
}

.ql-toolbar + .ql-container {
  border-top: 0px;
}

.ql-update-toolbar .ql-formats {
  margin-right: 10px !important;
}

.ql-update-toolbar .ql-picker-label {
  border: 1px solid transparent;
}

.ql-update-toolbar .ql-picker-options {
  border: 1px solid transparent;
  box-shadow: rgba(0, 0, 0, 0.2) 0 2px 8px;
}

.ql-update-toolbar .ql-picker.ql-expanded .ql-picker-label {
  border-color: #ccc;
}

.ql-update-toolbar .ql-picker.ql-expanded .ql-picker-options {
  border-color: #ccc;
}

.ql-update-toolbar .ql-color-picker .ql-picker-item.ql-selected,
.ql-update-toolbar .ql-color-picker .ql-picker-item:hover {
  border-color: #000;
}

.ql-update-toolbar + .ql-container {
  border-top: 0px;
}

.ql-tooltip {
  background-color: #fff;
  border: 1px solid #ccc;
  box-shadow: 0px 0px 5px #ddd;
  color: #444;
  padding: 5px 12px;
  white-space: nowrap;
}

.ql-tooltip::before {
  content: "Visit URL:";
  line-height: 26px;
  margin-right: 8px;
}

.ql-tooltip input[type="text"] {
  display: none;
  border: 1px solid #ccc;
  font-size: 13px;
  height: 26px;
  margin: 0px;
  padding: 3px 5px;
  width: 170px;
}

.ql-tooltip a.ql-preview {
  display: inline-block;
  max-width: 200px;
  overflow-x: hidden;
  text-overflow: ellipsis;
  vertical-align: top;
}

.ql-tooltip a.ql-action::after {
  border-right: 1px solid #ccc;
  content: "Edit";
  margin-left: 16px;
  padding-right: 8px;
}

.ql-tooltip a.ql-remove::before {
  content: "Remove";
  margin-left: 8px;
}

.ql-tooltip a {
  line-height: 26px;
}

.ql-tooltip.ql-editing a.ql-preview,
.ql-tooltip.ql-editing a.ql-remove {
  display: none;
}

.ql-tooltip.ql-editing input[type="text"] {
  display: inline-block;
}

.ql-tooltip.ql-editing a.ql-action::after {
  border-right: 0px;
  content: "Save";
  padding-right: 0px;
}

.ql-tooltip[data-mode="link"]::before {
  content: "Enter link:";
}

.ql-tooltip[data-mode="formula"]::before {
  content: "Enter formula:";
}

.ql-tooltip[data-mode="video"]::before {
  content: "Enter video:";
}

.ql-editor a {
  color: #06c;
}

@media (min-width: 1024px) {
  .ql-container {
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    min-height: 520px;
  }
}
.ql-container {
  min-height: 200px;
}

.ql-container.ql-snow {
  border: none !important;
}

.ql-editor * {
  font-stretch: normal;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: normal;
  font-family: "Pretendard";
}

.ql-editor p {
  color: #505254;
  margin-top: 2px;
  margin-bottom: 2px;
  line-height: 1.5;
  font-weight: 400;
  font-size: 14px;
  word-break: break-word;
  width: 100%;
  overflow-x: clip;
}

.ql-editor h2 {
  margin-bottom: 24px;
  margin-top: 24px;
}

.ql-editor h3 {
  margin-bottom: 24px;
}

.ql-editor h2 {
  font-size: 24px;
  font-weight: bold;
  line-height: 1.3;
}

@media (min-width: 1024px) {
  .ql-editor h2 {
    font-size: 32px !important;
    line-height: 1.5;
  }
}

.ql-editor h3 {
  font-size: 22px;
  font-weight: bold;
}

@media (min-width: 1024px) {
  .ql-editor h3 {
    font-size: 28px !important;
    line-height: 1.5;
  }
}

.ql-editor strong {
  font-weight: bold;
}

.ql-editor .ql-align-center img {
  display: block;
  margin: 2px auto;
}

.ql-editor blockquote,
.ql-editor ul,
.ql-editor ol {
  margin: 0 0 0px !important;
}

.ql-editor ul li,
.ql-editor ol li {
  margin-bottom: 4px;
}

.ql-editor ol {
  margin-left: 14px !important;
}

.ql-editor ul {
  margin-left: 10px !important;
}

.ql-editor a {
  color: #0085ff;
  font-weight: 500;
  line-height: 1;
}

.ql-editor li {
  padding-left: 0 !important;
  font-weight: 400;
  line-height: 1.3;
  font-size: 14px;
  color: #505254;
}
